$desktop-width: 1366px;
@mixin mobile {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$fullWidth: 100%;
$borderRadius: 4px;
$main_color: #0080cb;
$main_color-hover: #00a0fe;
$main_link: #1394df;
$main_size: 16px;
$font: "";
$color-border: #d9d9d9;
$color-border-2: #e6e6e6;
$color-border-3: #dfe6ec;
$nocolor: transparent;
$white: #ffffff;
$black: #000000;
$green: #61bc48;
$info-more: #6c757d;

$bg_mobile: #fff;

$padding_content: 20px;
$padding_content_mobile: 16px;

$bg_tab_active: #f4f4f4;
$bg_tab_other: white;
$tab_border_radius: 0px;
$hover_bg: #f7f9fc;
$bg_machine: #f7f9fc;

$second_color: #ffa228;
$text_color: #40445b;
$text_color_second: #a4afc1;
$title_color: #272727;
$text-danger: #ca5c54;
$web_bg: #f2f2f2;

$bg_disable_check: #e3e3e3;
$bg_un_check: #f7f9fc;
$bg_error_check: #f9a4a4;
$bg_complete_check: #b9ecdb;
$bg_checking: #ffea9e;

$disable_check: #6c757d;
$un_check: #6c757d;
$error_check: #d31515;
$complete_check: #2fad5a;
$checking: #f16900;

$i--error: #e13c3c;

@import "./mixin.scss";
