@import "../../styles/css/vars.scss";

.autoMail {
  &-input {
    // border: 1px solid #cbcbcb;
    // border-radius: 2px;
    // padding: 4px 11px;
    // font-size: 16px;
    height: 35.2px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-right: 53px;
    cursor: auto;
  }
  &-button {
    position: absolute;
    margin: 7px 0 0 0;
    right: 10px;
    span:active {
      color: $main_color;
    }
  }
  &-txt {
    display: none;
  }
  &-user {
    text-decoration: underline;
  }

  &-err {
    border-bottom: 2px dashed #dc0000;
    padding-bottom: 2px;
  }

  &-popup {
    .ant-modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      height: 413px;

      .ant-table-cell {
        font-size: $main_size;
      }
    }
  }

  &-listSelect {
    height: "35px";
  }

  &-disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
}
