@import "../../styles/css/vars.scss";

.footer {
  &-page {
    position: fixed;
    padding: 5px 16px;
    bottom: 0;
    z-index: 3;
    height: 60px;
    background-color: white;
    border-top: 1px solid #dee6ec;

    width: 100%;

    &-top {
      // margin: 50px;
      height: 50px;
      display: flex;
    }

    .col-border-left {
      border-left: 1px solid #dfe6ec;
      margin: -12px 0;
      padding: 0 40px !important;
    }
  }
}
