

.flow-builder-content {
  background-color: transparent !important;
  margin-left: -120px !important;
}
.start-node,
.end-node {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  line-height: 64px;
  color: #fff;
  text-align: center;
}


.other-node,
.condition-node {
  width: 230px;
  border-radius: 4px;
  color: #000;
  background: #fafafa;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  text-align: center;
}


.start-node {
  background-color: #338aff;
}

.end-node {
  background-color: #666;
}

.doing-node {
  background-color: rgb(255, 163, 38);
}

.confirmed-node {
  background-color: rgb(47, 173, 90);
}

.other-node {
  height: 65px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.condition-node {
  height: 20px;
  padding: 12px 16px;
}

.userLabel-style {
  font-size: 12px !important;
  color: #444444 !important;
}
