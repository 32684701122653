.approve-screen {
  .modal-custom-content {
    .modal-custom-icon {
      margin-top: -17px;
      float: left;
      font-size: 70px;
      color: #faac14;
    }

    font-size: 14px;
    padding-left: 83px;
    font-weight: 400;

    .modal-custom-title {
      font-weight: bold;
    }
  }
}
